




































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import ContractingStatusModerateRequestReceivedViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/contracting-status-moderate/contracting-status-moderate-request-received-view-model';

@Component({ name: 'ContractingStatusModerateSignatureReceived' })
export default class ContractingStatusModerateSignatureReceived extends Vue {
  @PropSync('goalName', { type: String, required: true })
  synced_goal_name!: string;

  contracting_status_moderate_request_received_model = Vue.observable(
    new ContractingStatusModerateRequestReceivedViewModel(this),
  );
}
